/*
    Site Customizations
*/
.dropdown-menu-end[data-bs-popper] {
    right: auto;
    left: auto;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

body.home {
    background-image: url(../images/bg.jpg);
    background-size: 1680px;
    background-position: center 10px;
    background-repeat: no-repeat;
}

.actions {
    background-color: $primary;
}

.bg-trans {
    background-color: transparent !important;
    border: none !important;
}

/*
    Header Customizations
*/
#top-bar {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    background-color: $white !important;

    #top-header, #bottom-header {
        width: 100%;

        .container-fluid {
            width: 1300px;

            @media (max-width: 1300px) {
                width: 992px;
            }

            @media (max-width: 992px) {
                width: 100%;
            }
        }
    }

    #top-header {
        background-color: $primary;
        border-bottom: 0.65rem solid $uccYellow;

        #user-menu {
            background-color: $white;
            border-color: $white;
            color: $black;

            &:hover, &:focus {
                background-color: $uccWhite;
                border-color: $uccWhite;
                box-shadow: none;
            }
        }

        a.nav-link {
            &:hover, &:focus {
                background-color: $uccWhite;
                color: $primary;

                i {
                    color: $primary;
                }
            }
        }
    }

    #bottom-header {
        background-color: $white;
        border-bottom: 1.3rem solid $primary;
        padding-bottom: 0.65rem;

        .navbar-toggler {
            margin-left: 1rem;

            &:hover, &:focus {
                background-color: $primary;
                border-color: $primary;
                box-shadow: none;
            }
        }

        #logo img {
            height: 60px;
            display: block;
        }

        #browse-menu, #search-box button {
            background-color: $primary;
            border-color: $primary;
            color: $white;

            &:focus, &:hover {
                box-shadow: none;

                &::after {
                    color: $gray;
                }
            }
        }

        #browse-menu {
            @media (max-width: 830px) {
                display: none;
            }
        }
    }
}

/*
    Footer Customizations
*/
footer {
    background-color: $primary;
    border-top: 10px solid $uccYellow;
    padding-top: .75rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .container {
        width: 1300px;
    }

    .links {
        line-height: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        color: $white;
    }

    hr {
        border: 0 solid $white;
        opacity: 1;
    }

    a {
        color: $white;
        font-size: 13px;
    }

    @media (min-width: 1300px) {
        border-radius: 50% 50% 0 0;
        padding-top: 50px;
        box-sizing: border-box;
    }

    @media (max-width: 992px) {
        margin: 0 1rem;
        width: unset;
        padding: 0;

        .container {
            margin: 0;
            padding: 0;
            max-width: 100%;
        }

        .d-flex {
            flex-direction: column !important;

            hr {
                display: none;
            }

            .links {
                padding-top: 1rem;
                width: 100%;
                border-top: 0.06125rem solid $white;
                border-bottom: 0.06125rem solid $white;
                margin: 0;
            }
        }
    }
}