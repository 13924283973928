/*

Customize variables:

- Check the ../../arDominionB5Plugin/scss/variables.scss file.
- Check the B5 documentation (https://getbootstrap.com/docs/5.0/customize/sass/).
- Import B5 functions, variables and mixins if needed.

*/
$primary: #002B55;

@import "../../arDominionB5Plugin/scss/main.scss";
@import "variables";
@import "custom";

/*

Extend:

- Add extra SCSS and CSS imports.
- Add custom styles directly.

*/
